<template>
  <div>
    <a-modal
      v-model="visibleModalPrestasi"
      title="Tambah Prestasi Peserta Didik"
      on-ok="handleOk"
      :destroyOnClose="true"
    >
      <a-form-model ref="ruleForm" :model="formPrestasi" :rules="rules">
        <a-form-model-item ref="jenis" :colon="false" prop="jenis">
          <span slot="label">
            Jenis Prestasi&nbsp;
            <a-tooltip
              title="Jenis prestasi yang pernah diraih oleh peserta didik."
            >
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>

          <a-select size="large" v-model="formPrestasi.jenis">
            <a-select-option
              v-for="item in jenisPrestasiList"
              :key="item.title"
              :value="item.title"
              >{{ item.title }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item ref="tingkat" :colon="false" prop="tingkat">
          <span slot="label">
            Tingkat Prestasi&nbsp;
            <a-tooltip
              title="Tingkat penyelenggaraan prestasi yang pernah diraih oleh peserta didik."
            >
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>

          <a-select size="large" v-model="formPrestasi.tingkat">
            <a-select-option
              v-for="item in tingkatPrestasiList"
              :key="item.title"
              :value="item.title"
              >{{ item.title }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item ref="nama" :colon="false" prop="nama">
          <span slot="label">
            Nama Prestasi&nbsp;
            <a-tooltip
              title="Nama kegiatan/acara dari prestasi yang pernah diraih oleh peserta didik. Contoh: Lomba Cerdas Cermat Bahasa Indonesia Tingkat SMP. Sesuaikan menurut piagam yang diperoleh."
            >
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>

          <a-input
            size="large"
            v-model="formPrestasi.nama"
            @blur="
              () => {
                $refs.nama.onFieldBlur();
              }
            "
          >
          </a-input>
        </a-form-model-item>
        <a-form-model-item ref="peringkat" :colon="false" prop="peringkat">
          <span slot="label">
            Peringkat&nbsp;
            <a-tooltip
              title="Peringkat kegiatan/acara dari prestasi yang pernah diraih oleh peserta didik."
            >
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>

          <a-input-number
            class="w-100"
            :min="1"
            size="large"
            :max="100"
            v-model="formPrestasi.peringkat"
          ></a-input-number>
        </a-form-model-item>
        <a-form-model-item ref="tahun" :colon="false" prop="tahun">
          <span slot="label">
            Tahun Prestasi&nbsp;
            <a-tooltip
              title="Tahun prestasi yang pernah diraih oleh peserta didik."
            >
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-input-number
            size="large"
            :max="9999"
            :min="1000"
            v-model="formPrestasi.tahun"
          ></a-input-number>
        </a-form-model-item>
        <a-form-model-item
          ref="penyelenggara"
          :colon="false"
          prop="penyelenggara"
        >
          <span slot="label">
            Penyelenggara&nbsp;
            <a-tooltip
              title="Nama penyelenggara/panitia kegiatan dari prestasi yang pernah diraih oleh peserta didik. Contoh: Panitia O2SN dan FL2SN Kab. Bengkayang. Sesuaikan menurut piagam yang diperoleh."
            >
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-input
            size="large"
            v-model="formPrestasi.penyelenggara"
            @blur="
              () => {
                $refs.penyelenggara.onFieldBlur();
              }
            "
          >
          </a-input>
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <a-button key="back" @click="handleVisibleModalPrestasi"
          >Batal</a-button
        >
        <a-button
          key="submit"
          type="primary"
          :loading="loadingModalPrestasi"
          @click="handleOkModalPrestasi"
        >
          Tambah
        </a-button>
      </template>
    </a-modal>
    <a-table
      :columns="dataColumns"
      :data-source="dataTable"
      :pagination="{ pageSize: 30, hideOnSinglePage: true }"
      bordered
      size="large"
    >
      <template v-if="!isAdmin" slot="title">
        <a-button
          @click="handleVisibleModalPrestasi"
          size="large"
          type="default"
          class="btn btn-outline-primary rounded my-2"
          >Tambah Prestasi</a-button
        >
      </template>
      <span slot="aksi" slot-scope="text, record">
        <a-button
          @click="deleteRow(record.key)"
          type="default"
          size="large"
          class="btn btn-outline-danger rounded"
          >Hapus</a-button
        >
      </span>
    </a-table>
  </div>
</template>

<script>
import { jenisPrestasiList, tingkatPrestasiList } from '@/helpers/listsPpdbSelect'
const column = [
  {
    dataIndex: 'jenis',
    key: 'jenis',
    title: 'Jenis',
    scopedSlots: { customRender: 'jenis' },
    align: 'left',
  },
  {
    dataIndex: 'tingkat',
    key: 'tingkat',
    title: 'Tingkat',
    scopedSlots: { customRender: 'tingkat' },
    align: 'left',
  },
  {
    dataIndex: 'nama',
    key: 'nama',
    title: 'Nama',
    scopedSlots: { customRender: 'nama' },
    align: 'left',
  },
  {
    dataIndex: 'peringkat',
    key: 'peringkat',
    title: 'Peringkat',
    scopedSlots: { customRender: 'peringkat' },
    align: 'left',
  },
  {
    dataIndex: 'tahun',
    key: 'tahun',
    title: 'Tahun',
    scopedSlots: { customRender: 'tahun' },
    align: 'left',
  },
  {
    dataIndex: 'penyelenggara',
    key: 'penyelenggara',
    title: 'Penyelenggara',
    scopedSlots: { customRender: 'penyelenggara' },
    align: 'left',
  },
  {
    dataIndex: 'aksi',
    key: 'aksi',
    title: 'Aksi',
    scopedSlots: { customRender: 'aksi' },
    align: 'center',
  },
]
const rules = {
  jenis: [
    { required: true, message: 'Jenis Prestasi wajib diisi!', trigger: 'change' },
  ],
  tingkat: [
    { required: true, message: 'Tingkat Prestasi wajib diisi!', trigger: 'change' },
  ],
  nama: [
    { required: true, message: 'Nama Prestasi wajib diisi!', trigger: 'blur' },
  ],
  peringkat: [
    { required: true, message: 'Peringkat wajib diisi!', trigger: 'change' },
  ],
  penyelenggara: [
    { required: true, message: 'Penyelenggara wajib diisi!', trigger: 'blur' },
  ],
  tahun: [
    {
      required: true,
      validator: (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Tahun Prestasi wajib diisi!'))
        }
        if (!Number.isInteger(value)) {
          callback(new Error('Tahun Prestasi harus angka!'))
        } else {
          callback()
        }
      },
      trigger: 'blur',
    },
    // { len: 4, message: 'Tahun Prestasi harus 4 digit!', trigger: 'change' },
  ],
}
export default {
  props: {
    dataTable: {
      type: Array,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      column,
      jenisPrestasiList,
      tingkatPrestasiList,
      rules,
      visibleModalPrestasi: false,
      loadingModalPrestasi: false,
      formPrestasi: {
        jenis: '',
        tingkat: '',
        nama: '',
        tahun: '',
        penyelenggara: '',
        peringkat: '',
      },
    }
  },
  computed: {
    dataColumns() {
      if (this.isAdmin) {
        return this.column.filter(row => row.key !== 'aksi')
      } else {
        return this.column
      }
    },
  },
  methods: {
    handleVisibleModalPrestasi() {
      this.visibleModalPrestasi = !this.visibleModalPrestasi
    },
    handleOkModalPrestasi() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$emit('added', this.formPrestasi)
          this.handleVisibleModalPrestasi()
          this.formPrestasi = {
            jenis: '',
            tingkat: '',
            nama: '',
            tahun: '',
            penyelenggara: '',
            peringkat: '',
          }
        } else {
          console.log('error submit!!')
          this.$notification.error({
            message: 'Maaf',
            description: 'Ada data wajib yang belum terisi.',
          })
          return false
        }
      })
    },
    deleteRow(value) {
      this.dataTable.splice(this.dataTable.findIndex(el => el.key === value), 1)
    },
  },
}
</script>

<style>
</style>
